

.serviciosMain{
    background: white;
    display: flex;
    flex-flow: column nowrap;
    //Prueba
    // height: 200vh;    
    // background-color:darksalmon;  

}


//////////////Titulo/////////////////////////////////////////

.serviciosTitulo{

    font-size: 75px;
    font-weight: 600;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 200px; 
    margin-left: 10px;
    width: 400px;
    @media screen and (max-width: 900px){
        margin-top: 100px; 
        font-size: 60px;
        width: 340px;
    }
    @media screen and (max-width: 790px){
        font-size: 55px;
        width: 320px;
    }
    @media screen and (max-width: 790px){
        font-size: 50px;
        width: 300px;
    }
    @media screen and (max-width: 685px){
        font-size: 45px;
        width: 265px;
    }
    @media screen and (max-width: 660px){
        font-size: 40px;
        width: 250px;
    }
    @media screen and (max-width: 550px){

        font-size: 38px;
        width: 240px;
    }
    @media screen and (max-width: 502px){
        margin-top: 20px; 

    }
    @media screen and (max-width: 375px){

        font-size: 35px;
        width: 170px;
    }

    //Prueba   
    // background-color: darkviolet;
}


//////////////Contenido//////////////////////////////////////

.serviciosContenido{

    display: flex;
    flex-flow: column wrap;    
    margin-right: 30px;
    margin-left: 50px;
    margin-top: 40px;
    margin-bottom: 20px ;
    //Prueba
    // background-color: brown;
    @media screen and (max-width: 530px){
       margin-top: 20px;
    }
    @media screen and (max-width: 375px){
        margin-right: 20px;
        margin-left: 20px;
     }
    .lineMenu{
        border-bottom: 3px solid #E5E5E5;
    }
    .lineMenuTop{
        border-top: 3px solid #E5E5E5;
    }
    .serviciosItem{
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        margin: 10px;
        margin-left: 0px;        
        //Prueba
        // background-color: deeppink;
        .serviciosMenu{
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content:space-between;
            padding: 20px 0;
            //prueba
            // background-color: forestgreen;
            .serviciosMenuTitulo{
                font-size: 23px;
                font-weight: 600;
                @media screen and (max-width: 900px){
                    font-size: 20px;
                }
                @media screen and (max-width: 790px){
                    font-size: 18px;
                }
                @media screen and (max-width: 720px){
                    font-size: 17px;
                }
                @media screen and (max-width: 685px){
                    font-size: 16px;
                }
                @media screen and (max-width: 660px){
                    font-size: 15px;
                }
                @media screen and (max-width: 625px){
                    font-size: 14px;
                }
                @media screen and (max-width: 590px){
                    font-size: 13px;
                }
                @media screen and (max-width: 414px){
                    max-width: 90%;
                    line-height: 1.2rem;
                }
                @media screen and (max-width: 320px){
                    font-size: 10px;
                }
                @media screen and (max-width: 280px){
                    max-width: 80%;
                    font-size: 8px;
                }

            }
            .serviciosMenuIconoCirculo{
                padding: 5px;
                background-color: #E5E5E5;
                border-radius: 50%;
                cursor: pointer;
                .serviciosMenuIcono {
                    font-size: 30px;
                    color: black;
                    @media screen and (max-width: 900px){
                        font-size: 25px;
                    }
                    @media screen and (max-width: 790px){
                        font-size: 23px;
                    }
                    @media screen and (max-width: 625px){
                        font-size: 20px;
                    }
                    @media screen and (max-width: 530px){
                        font-size: 18px;
                    }
                }
            }
            .serviciosMenuIconoCirculoDespliege{
                padding: 5px;
                background-color: black;
                border-radius: 50%;
                cursor: pointer;
                .serviciosMenuIconoDespliege {
                    font-size: 30px;
                    color: white;
                    @media screen and (max-width: 900px){
                        font-size: 25px;
                    }
                    @media screen and (max-width: 790px){
                        font-size: 23px;
                    }
                    @media screen and (max-width: 625px){
                        font-size: 20px;
                    }
                    @media screen and (max-width: 530px){
                        font-size: 18px;
                    }
                }
            }
        }
        .serviciosMenuContenido{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;            
            padding: 40px;
            padding-left:15px;
            padding-bottom: 100px;
            //prueba

            // background-color:gold;
            @media screen and (max-width: 1100px){
                justify-content: space-around; 
            }
            @media screen and (max-width: 850px){
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center; 
                padding: 40px;
                padding-bottom: 10px;
                padding-top: 20px;
            }
            @media screen and (max-width: 720px){
                padding: 10px;
            }
            @media screen and (max-width: 530px){
                padding: 20px;
            }
            @media screen and (max-width: 375px){
                padding: 10px;
            }
        }

        .serviciosMenuContenidoTexto{
            display: flex;
            flex-flow: column wrap;
            font-size: 17px;
            font-weight: 400;
            width: 60%;
            line-height: 1.5;
            p{
                margin: 15px;
                margin-right: 20px;
                margin-top: 10px;
            }
            //prueba
            
            // background-color: khaki;
            @media screen and (max-width: 1100px){
                font-size: 15px;
            }
            @media screen and (max-width: 900px){
                font-size: 13px;
            }
            @media screen and (max-width: 850px){
                width: 100%;
            }
            @media screen and (max-width: 620px){
                font-size: 12px;
            }
            @media screen and (max-width: 375px){
                font-size: 11px;
                p{
                    margin: 10px;
                }
            }
            @media screen and (max-width: 280px){
                font-size: 8px;
            }

        }
        
        .serviciosMenuContenidoLista{
            width: 40%;
            //prueba
            // background-color: hotpink;
            @media screen and (max-width: 850px){
                margin-top: 20px;
                width: 100%;
            }
            .menuListaItem{
                display: flex;
                flex-flow: wrap  nowrap;
                margin: 10px;
                padding-bottom: 40px;
                //prueba
                // background-color: lightseagreen;
                .menuListaNombre{
                    margin-left: 20px;
                    font-size: 15px;
                    font-weight: 400;
                    @media screen and (max-width: 900px){
                        font-size: 13px;
                    }
                    @media screen and (max-width: 570px){
                        font-size: 11px;
                    }
                }
                .menuListaNumero{
                    color: rgba($color: #000000, $alpha: 0.6);
                    font-size: 15px;
                    font-weight: 400;
                    @media screen and (max-width: 900px){
                        font-size: 13px;
                    }
                    @media screen and (max-width: 570px){
                        font-size: 11px;
                    }
                }
            }
        }
    }
}


//////////////Quote/////////////////////////////////////////


.serviciosQuote{
    
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 120px 20px;
    @media screen and (max-width: 690px){
        padding: 100px 20px;
    }
    @media screen and (max-width: 590px){
        padding: 80px 20px;
    }
    
    //Prueba
    // height: 400px;
    
    background-color: rgba($color: #203864, $alpha: 1.0);
    .serviciosQuoteItem{

        display: flex;
        flex-flow: column nowrap;
        width: 65%;
        height: 70%;
        //Prueba
        // background-color:forestgreen;
        @media screen and (max-width: 960px){
            width: 80%;
        }
        @media screen and (max-width: 411px){
            width: 100%;
        }

        .serviciosQuoteTexto{
            color: white;
            display: flex;
            flex-flow: row nowrap;
            .serviciosQuoteComilla{
                font-family: 'Prata'!important;
                font-size: 120px;
                font-weight: 400;
                padding-right: 10px;
                @media screen and (max-width: 900px){
                    font-size: 100px;
                }
                @media screen and (max-width: 690px){
                    font-size: 80px;
                }
                @media screen and (max-width: 590px){
                    font-size: 60px;
                }
                @media screen and (max-width: 320px){
                    font-size: 50px;
                }


            }
            .serviciosQuoteContenido{
                padding-top: 8px;
                font-size: 30px;
                font-weight: 300;
                line-height: 50px;
                span{
                    font-weight: bold;
                }
                @media screen and (max-width: 900px){
                    line-height: 40px;
                    font-size: 25px;
                }
                @media screen and (max-width: 690px){
                    line-height: 35px;
                    padding-top: 0px;
                    font-size: 20px;
                }
                @media screen and (max-width: 590px){
                    line-height: 30px;
                    font-size: 15px;
                }
                @media screen and (max-width: 320px){
                    line-height: 20px;
                }

            }
        }
        .serviciosQuoteAutor{
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: center;
            width: 50%;
            margin-top: 10px;
            margin-left: 60px;
            font-weight: 400;
            @media screen and (max-width: 900px){
                font-size: 14px;
                margin-left: 50px;
            }
            @media screen and (max-width: 690px){
                font-size: 12px;
                margin-left: 40px;
            }
            @media screen and (max-width: 590px){
                font-size: 10px;
                line-height: 10px;
                margin-left: 35px;
            }
            @media screen and (max-width: 520px){
                font-size: 9px;

            }
            @media screen and (max-width: 320px){
                font-size: 8px;
                margin-left: 30px;
            }
            //prueba
            // background-color: blue;
            .serviciosQuoteNombre{
                color: white;
                margin-bottom: 8px;
                @media screen and (max-width: 320px){
                    margin-bottom: 5px;
                }
            }
            .serviciosQuotePuesto{
                color: #838282;
                padding-bottom: 5px;
                border-bottom: 2px solid white ;
                
            }
        }
    }
}