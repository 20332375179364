

.footerContenedor{
    display: flex;
    flex-flow: column nowrap;
    color: white;
    //prueba
    width: 100%;
    background-color: #203864;
    a{
        color: white;
    }
}

////////////////////Parte Superior/////////////////////////////

.footerParteSuperior{

    display: flex;
    flex-flow: row nowrap;
    //prueba
    padding-top: 20px;
    padding-bottom: 150px;
    @media screen and (max-width: 750px){
        padding-bottom: 100px;
    }
    @media screen and (max-width: 414px){
        flex-flow: column nowrap;
        padding-top: 30px;
        padding-bottom: 10px;
        // justify-content: space-between;
    }

    // background-color: yellow;

        .footerSocial{
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 30px;
            width: 25%;
            @media screen and (max-width: 770px){
                padding: 20px;
            }
            @media screen and (max-width: 700px){
                padding: 10px;
            }
            @media screen and (max-width: 414px){
                height: 20%;
                width: 100%;
                padding: 10px;
                flex-flow: row nowrap;
                margin-bottom: 15px;
                
            }
            //prueba
            // background-color: cadetblue;
            .social{
                font-size: 30px;
                margin-bottom: 10px;
                @media screen and (max-width: 1100px){
                    font-size: 20px;
                }
                @media screen and (max-width: 650px){
                    font-size: 15px;
                }
                @media screen and (max-width: 414px){
                    display: flex;
                    align-items: center;
                    margin-left: 20px;
                    padding-right:71px ;
                    height: 30px;
                    border-right: 2px solid white;
                }
                @media screen and (max-width: 280px){
                    margin-left: 10px;


                }


            }
            .redesContenido{
                display: flex;
                flex-flow: column nowrap;

         
                @media screen and (max-width: 414px){
                    margin-left: 110px;
                }
                @media screen and (max-width: 375px){
                    margin-left: 86px;
                }
                @media screen and (max-width: 360px){
                    margin-left: 49px;
                }
                @media screen and (max-width: 280px){
                    margin-left: 10px;
                }
                // background-color: blue;
                .redes{
                //    background-color: blueviolet;
                   padding: 7px; 
                   padding-left: 0;
                    @media screen and (max-width: 1100px){
                        font-size: 15px;
                        padding-left: 22px;
                    }
                    @media screen and (max-width: 950px){
                        font-size: 13px;
                        padding-left: 15px;
                    }
                    @media screen and (max-width: 650px){
                        padding-left: 16px;
                        font-size: 11px;
                    }
                    @media screen and (max-width: 550px){
                        padding-left: 10px;
                        font-size: 9px;
                    }
                    @media screen and (max-width: 414px){
                        padding: 4px;
                    }
                    @media screen and (max-width: 360px){
                        padding: 3px;
                        font-size: 8px;
                    }
                }
            }
        }
        .footerContacto{
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 30px;
            width: 50%;
            @media screen and (max-width: 770px){
                padding: 20px;
            }
            @media screen and (max-width: 700px){
                padding: 10px;
            }
            @media screen and (max-width: 414px){
                height: 20%;
                width: 100%;
                flex-flow: row nowrap;
            }
            .footerContactoContenido{
                @media screen and (max-width: 414px){
                    margin-left: 112px;
                }
                @media screen and (max-width: 375px){
                    margin-left: 90px;
                }
                @media screen and (max-width: 360px){
                    margin-left: 50px;
                }
                @media screen and (max-width: 280px){
                    margin-left: 10px;
                }
                .datos{

                    padding: 4px; 
                    padding-left: 0;
                    @media screen and (max-width: 1100px){
                        font-size: 15px;
                        padding-left: 2px;
                    }
                    @media screen and (max-width: 950px){
                        font-size: 13px;
                    }
                    @media screen and (max-width: 650px){
                        font-size: 11px;
                    }
                    @media screen and (max-width: 550px){
                        font-size: 9px;
                    }
                    @media screen and (max-width: 414px){
                        font-size: 8px;
                    }
                }
            }
            //prueba
            // background-color: chocolate;
            .contacto{
                font-size: 30px;
                margin-bottom: 10px;
                @media screen and (max-width: 1100px){
                    font-size: 20px;
                }
                @media screen and (max-width: 650px){
                    font-size: 15px;
                }
                @media screen and (max-width: 414px){
                    margin-left: 20px;
                    padding-right:45px ;
                    height: 30px;
                    border-right: 2px solid white;
                    display: flex;
                    align-items: center;
                }
                @media screen and (max-width: 280px){
                    margin-left: 11px;
                }

            }

        }
        .footerOficina{
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 30px; 
            width: 25%;
            @media screen and (max-width: 770px){
                padding: 20px;
            }
            @media screen and (max-width: 700px){
                padding: 10px;
            }
            @media screen and (max-width: 414px){
                margin-top: 15px;
                height: 20%;
                width: 100%;
                padding: 10px;
                padding-bottom: 0;
                flex-flow: row nowrap;
            }
           
            //prueba
            // background-color: crimson;
            .oficina{
                font-size: 28px;
                margin-bottom: 10px;
                @media screen and (max-width: 1100px){
                    font-size: 20px;
                }
                @media screen and (max-width: 650px){
                    font-size: 15px;
                }
                @media screen and (max-width: 414px){
                    margin-left: 20px;
                    padding-right:63px ;
                    height: 30px;
                    border-right: 2px solid white;
                    display: flex;
                    align-items: center;
                }
                @media screen and (max-width: 280px){
                    margin-left: 11px;
                }
            }
            .footerOficinaContenido{
                @media screen and (max-width: 414px){
                    margin-left: 112px;
                }
                @media screen and (max-width: 375px){
                    margin-left: 90px;
                }
                @media screen and (max-width: 360px){
                    margin-left: 50px;
                }
                @media screen and (max-width: 280px){
                    margin-left: 10px;
                }
            }
            .direccion{
                padding: 4px; 
                padding-left: 0;
                @media screen and (max-width: 1100px){
                    font-size: 15px;
                    padding-left: 2px;
                }
                @media screen and (max-width: 950px){
                    font-size: 13px;
                }
                @media screen and (max-width: 650px){
                    font-size: 11px;
                }
                @media screen and (max-width: 550px){
                    font-size: 9px;
                }
                @media screen and (max-width: 360px){
                    padding: 3px;
                    font-size: 7px;
                }
            }
        }
}

////////////////////PArte Inferior/////////////////////////////

.footerParteInferior{

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: 20%;
    padding: 30px;
    padding-left: 90px;
    padding-right: 50px;
    @media screen and (max-width: 1100px){
        font-size: 15px;
        
    }
    @media screen and (max-width: 950px){
        font-size: 13px;
        padding-left: 80px;
        padding-right: 40px;
    }
    @media screen and (max-width: 800px){
        font-size: 12px;
        padding-top: 10px;
        padding-left: 60px;
        padding-right: 40px;
    }
    @media screen and (max-width: 750px){
        padding-left: 50px;
        padding-right: 30px;
        font-size: 11px;
    }
    @media screen and (max-width: 650px){
        padding-left: 40px;
        padding-right: 20px;
        font-size: 10px;
    }
    @media screen and (max-width: 600px){
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        font-size: 10px;
    }
    @media screen and (max-width: 414px){
        margin-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
        height: 20%;
        padding-bottom: 20px;
        font-size: 12px;
        .footerEmpresaLink{
            display: none;
        }
    }
    @media screen and (max-width: 320px){
        font-size: 9px;
    }
    

    //prueba
    // background-color: brown;

}

.footerAvisoPrivacidad{
    cursor: pointer;
}
.footerAvisoPrivacidad:hover{
    text-decoration: underline;
}

.footerEmpresaLinkMovil{
    display: none;
    font-size: 10px;
    @media screen and (max-width: 414px){
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    @media screen and (max-width: 375px){
       
    }
    @media screen and (max-width: 320px){
        font-size: 8px;
    }
}