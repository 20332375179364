$blue: #203864;
$gold: #BF9000;

header{
    height: 70px;
    position: fixed;
    width: 100%;
    z-index: 9000;
    background-color: rgba($color: #fff, $alpha: 1);
    .inner-header{
        // background-color: blue;
        position: relative;
        z-index: 10;
        height: 70px;
        display: flex;
        justify-content: space-between ;
        align-items: center;
        .logo{                
            font-weight: 700;
            font-size: 30px;
            color: $blue;
            span{
                color: $gold;            }
        }
        .menu{
            button{
                border: none ;
                background: none;
                outline: none;
                cursor: pointer;
                font-size: 0.8rem;
                color: black;
            }
        }
    }
}

//hamburger
.hamburger-menu{
    display: none;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    position: fixed ;
    height: 100%;
    width: 50%;    
    .menu-secondary-background-color{
        background-color: $blue;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 50%;
        right: 0;
        position: fixed ;
        height: 100%;
        width: 50%;        
    }
    .menu-layer{
        // position: relative;
        background-color: $gold;
        height: 100%;
        overflow: hidden;
        .wrapper{
            height: 100vh;
            display: flex;
            flex-flow: column;
            justify-content: center;  
         
                  
            .menu-links{
                display: flex;
                flex-flow: column;
                // align-items: center;
                justify-content: center;
                position: relative;
                // top: 200px;
                height: 100%;
                margin-left: 50px;
                nav{
                    display: block;
                    ul{
                        padding: 0;                        
                        height: 100%;
                        display: flex;
                        flex-flow: column;
                        // justify-content: space-evenly;

                        li{      
                            color: black;                      
                            list-style: none;
                            font-size: 3.5rem;
                            font-weight: 500;
                            cursor: pointer;
                            // height: 120px;
                            overflow: hidden;
                            margin-bottom: 30px;
                            &:hover{
                                color: white;
                            }
                        }
                    }
                }
                .aviso{
                    // color: white;
                    text-align: right;
                    padding-right: 25px;
                    margin-top: 16px;
                    font-size: 0.8rem;
                    font-weight: 300;
                    cursor: pointer;
                    &:hover {
                        color: #fff;
                      }


                }
            }
        }
    }
}
@media screen and (max-width: 700px) and (min-width: 415px) {
    .hamburger-menu{        
        width: 75% ;             
        left: 25%;
    }  
 
}


@media screen and (max-width: 414px) {
    .hamburger-menu{
        left: 0% ;
        width: 100% ;             
    }  
    .menu-secondary-background-color{
        display: none;
    }

}

@media screen and (max-width: 321px) {
    
    .menuLi{
        font-size: 2.5rem !important;
    }
    
}