$azul: #203864;
$amarillo: #BF9000;

.ejemplo{
    width: 100%;
    height: 100vh;
    background-color: white;
}

// ---------------- NOSOTROS ----------------------

.nosotros-fondo{
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-image: url('./../../images/imagenGarin12.jpeg');
    background-size: cover;

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}

.sombra-fondo{
    width: 100%;
    height: 100%;
    background-color: rgba($color: $azul, $alpha: 0.6);
}

.nosotros-container{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.columna-izquierda{
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    
    @media screen and (max-width: 575px){
        display: none;
    }
}

.nosotros-titulo{
    color: white;
    width: 70%;
    h1{
        font-size: 4rem;
        font-weight: 300;
        margin-bottom: 50px;
        @media screen and (max-width: 790px){
            font-size: 3rem;
        }
    }
    p{
        width: 85%;
        font-size: 20px;
        font-weight: 300;
        float: right;
        line-height: 25px;
        @media screen and (max-width: 790px){
            font-size: 17px;
            float: none;
            width: 100%;
        }
    }
    @media screen and (max-width: 575px){
        width: 90%;
    }
}

.divider{
    border: 1px solid white;
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
    margin-top: 50px;
}

.scroll-container{
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    // min-height: 100vh;
    padding-bottom: 80px;
    .columna-derecha{
        color: white;
        display: flex;
        flex-flow: column;
        padding: 50px 50px 0 50px;
        margin-top: -100vh;
        .nosotros-titulo{
            h1{
                margin-bottom: 20px;
            }
            @media screen and (min-width: 576px){
                display: none;
            }
        }
        h3{
            margin-top: 30px;
            font-size: 25px;
            line-height: 2rem;
        }
        p{
            width: 90%;
            margin-top: 20px;
            font-size: 18px;
            font-weight: 300;
            float: right;
            line-height: 25px; 
            @media screen and (max-width: 575px){
                width: 100%;
            }
        }
    }  
    
    .numero-redondo{
        background-color: $azul;
        display: flex;
        flex-flow: column;
        font-size: 60px;
        font-weight: 600;
        justify-content: center;
        width: 120px;
        height: 120px;
        border: 10px solid white;
        border-radius: 50%;
        text-align: center;
        @media screen and (max-width: 790px){
            width: 100px;
            height: 100px;
        }
    }

}



// ---------------- MISIÓN Y VISIÓN ----------------------



.mv-container{
    width: 100%;
    height: 100vh;
    background-color: white;
}

.circulos-container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 620px){
        flex-flow: column;
    }
}

.circulo-animado{
    width: 220px;
    height: 220px;
    background-color: rgba($color: $azul, $alpha: 0.3);
    border-radius: 50%;
    @media screen and (max-width: 620px){
        flex-flow: column;
        width: 150px;
        height: 150px;
    }
}

.azul{
    margin-right: 100px;
    animation: circle 6s linear infinite;
    @media screen and (max-width: 620px){
        margin-bottom: 40px;
    }
}

.amarillo{
    background-color: rgba($color: $amarillo, $alpha: 0.3) !important;
    animation: circle2 6s linear infinite;
}

.mv-texto-container{
    padding: 0 20px;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-flow: column;
    justify-content: center;
    .mv-row{
        display: flex;
        flex-flow: row;
        justify-content: center;
        @media screen and (max-width: 500px){
            flex-flow: column-reverse;
        }
        :first-child{
            padding-right: 30px;
        }
        p{
            width: 400px;
            font-size: 1.1em;
            font-weight: 300;
            line-height: 1.2rem;
            @media screen and (max-width: 1020px){
                width: 45%;
                font-size: 15px;
                padding: 0 20px;
            }
            @media screen and (max-width: 900px){
                font-size: 12px;
            }
            @media screen and (max-width: 700px){
                width: 60%;
            }
            @media screen and (max-width: 500px){
                width: 100%;
            }
            @media screen and (max-width: 340px){
                line-height: 1rem;
                font-size: 11px;
            }    
        }
        h3{
            font-size: 80px;
            @media screen and (max-width: 1020px){
                width: 55%;
                font-size: 60px;
            }
            @media screen and (max-width: 900px){
                font-size: 50px;
                padding-right: 10px;
            }
            @media screen and (max-width: 700px){
                width: 40%;
            }
            @media screen and (max-width: 500px){
                width: 100%;
                text-align: right;
                margin-bottom: 20px;
                font-size: 40px;
            }
            @media screen and (max-width: 340px){
                font-size: 30px;
            }  
        }
    }
    .first-row{
        align-items: flex-end;
        margin-bottom: 30px;
        h3{
            text-align: right;
            @media screen and (max-width: 500px){
                text-align: left;
            }
        }
        @media screen and (max-width: 500px){
            flex-flow: column;
        }
    }
}

@keyframes circle{
    0%{
      transform:rotate(0deg)
                translate(-10px)
                rotate(0deg);
    
    }
    100%{
      transform:rotate(360deg)
                translate(-10px)
                rotate(-360deg);
    }
}

@keyframes circle2{
    0%{
      transform:rotate(0deg)
                translate(10px)
                rotate(0deg);
    
    }
    100%{
      transform:rotate(360deg)
                translate(10px)
                rotate(-360deg);
    }
}


// ---------------- COLABORAR ----------------------


.colaborar-container{
    width: 100%;
    padding: 10vh 0 0vh 0;
    background-color: white;
    color: white;
    -webkit-transform: translate3d(0,0,0);
    @media screen and (max-width: 500px){
        padding: 0 0 10vh 0;
    }
}

.cuadro-azul{
    padding: 60px 20px;
    background-color: $azul;
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;
    @media screen and (max-width: 700px){
        flex-flow: column;
    }
    .texto-boton{
        width: 50%;
        font-size: 15px;
        font-weight: 400;
        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        line-height: 18px;
        @media screen and (max-width: 700px){
            width: 100%;
        }
        .btn-empezar-proyecto{
            border: 2px solid white;
            width: 200px;
            padding: 10px 20px;
            border-radius: 50px;
            text-align: center;
            margin-top: 30px;
            font-weight: 300;
            cursor: pointer;
        }
        .btn-empezar-proyecto:hover{
            background-color: white;
            color: $azul;
        }
    }
    h3{
        font-size: 50px;
        width: 50%;
        text-align: center;
        @media screen and (max-width: 700px){
            width: 100%;
            margin-bottom: 40px;
        }
        @media screen and (max-width: 340px){
            font-size: 35px;
        }
    }
}


