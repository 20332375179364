$dorado: #BF9000;

.avisoDePrivacidadContainer{
    width: 100%;
    min-height: 100vh;
    top: 0;
    z-index: 10000;
    background-color: $dorado;
}

.avisoDePrivacidad-content{
    display: flex;
    flex-flow: column nowrap;
    margin: 0 50px;
    padding-bottom: 50px;
    align-items: center;
    justify-content: center;
    color:white;
    font-size: 14px;
    @media screen and (max-width: 411px){
        margin: 0 30px;
    }
    @media screen and (max-width: 320px){
        margin: 0 20px;
    }
    @media screen and (max-width: 280px){
        margin: 0 10px;
    }
}
.tituloAvisoPrivacidad{
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 30px;
    @media screen and (max-width: 800px){
        font-size: 30px;
    }
    @media screen and (max-width: 600px){
        font-size: 25px;
        margin-bottom: 25px;
    }
    @media screen and (max-width: 411px){
        font-size: 25px;
    }
    @media screen and (max-width: 375px){
        font-size: 20px;
    }
}
.contentAviso-p{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin: 0 20px;
}
.subtituloAvisoPrivacidad{
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    line-height: 1.2;
    @media screen and (max-width: 800px){
        font-size: 14px;
    }
    @media screen and (max-width: 600px){
        font-size: 12px;
    }
}
.parrafosAviso{
    font-size: 15px;
    line-height: 1.5;
    margin: 10px 0px;
    @media screen and (max-width: 900px){
        margin: 10px 0px;
    }
    @media screen and (max-width: 800px){
        font-size: 13px;
        margin: 8px 0px;
    }
    @media screen and (max-width: 600px){
        font-size: 11px;
        margin: 8px 0px;
    }
}