
$dorado: #BF9000;

.bold{
    font-weight: 500;
}

.contacto-container{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 10000;
    background-color: $dorado;
    
}

.btn-cerrar{
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    padding: 20px;
    .cerrar-icon{
        font-size: 30px;
        font-weight: 100;
        cursor: pointer;
        color: white;
    }
}

.contacto-content{
    color: white;
    font-weight: 300;
    display: flex;
    height: 80%;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    span{
        overflow: hidden;
        max-width: 70%;
        @media screen and (max-width: 411px){
            max-width: 65%;
        }
        @media screen and (max-width: 360px){
            max-width: 60%;
        }
        @media screen and (max-width: 280px){
            max-width: 80%;
        }
    }
    .pregunta{
        font-size: 25px;
        margin-bottom: 30px;
        font-weight: 200;
        @media screen and (max-width: 620px){
            text-align: center;
            font-size: 20px;
        }
        @media screen and (max-width: 411px){
            line-height: 35px;
            font-size: 22px;
        }
        @media screen and (max-width: 360px){
            font-size: 20px;
            margin-bottom: 15px;
            line-height: 32px;
        }
        @media screen and (max-width: 280px){
            line-height: 30px;
        }
    }
    .pregunta-final{
        margin-bottom: 50px;
        text-align: center;
        font-size: 30px;
        line-height: 40px;
        @media screen and (max-width: 680px){
            font-size: 26px;
            margin-bottom: 40px;
        }
        @media screen and (max-width: 620px){
            font-size: 25px;
            margin-bottom: 40px;
        }
        @media screen and (max-width: 580px){
            font-size: 23px;
            margin-bottom: 30px;
        }
        @media screen and (max-width: 525px){
            font-size: 22px;
        }
        @media screen and (max-width: 515px){
            font-size: 21px;
        }
        @media screen and (max-width: 411px){
            line-height: 35px;
            font-size: 24px;
        }
        @media screen and (max-width: 360px){
            line-height: 32px;
            font-size: 20px;
        }
    }
    .contacto-input-text{
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba($color: white, $alpha: 0.8);
        color: white;
        font-size: 20px;
        font-weight: 200;
        width: 100%;
        margin-bottom: 20px;
        margin-left: 20px;
        @media screen and (max-width: 620px){
            font-size: 18px;
        }
        @media screen and (max-width: 411px){
            font-size: 20px;
        }
        @media screen and (max-width: 411px){
            font-size: 18px;
        }
    }
    .contacto-input-text::placeholder{
        color: rgba($color: white, $alpha: 0.6);
        font-weight: 300;
    }
    .contacto-input-text:focus{
        outline: none;
        border-bottom: 2px solid rgba($color: white, $alpha: 0.8);
    }
    .btn-siguiente{
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        .btn-siguiente-span{
            border: 2px solid white;
            border-radius: 50%;
            padding: 10px;
            font-size: 25px;
            cursor: pointer;
            @media screen and (max-width: 620px){
                font-size: 20px;
            }
            @media screen and (max-width: 411px){
                margin-top: 10px;
                font-size: 25px;
            }
            @media screen and (max-width: 360px){
                margin-top: 0px;
                font-size: 20px;
            }
        }
        .btn-siguiente-span:hover{
            background-color: white;
            color: $dorado;
        }
    }
    .siguiente-anterior{
        justify-content: space-between;
    }
    .opciones-container{
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        margin-bottom: 10px;
        .opcion{
            border: 1px solid white;
            color: white;
            padding: 10px;
            margin-bottom: 20px;
            cursor: pointer;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 360px){
                margin-bottom: 12px;
                padding: 8px;
                font-size: 15px;
            }
            @media screen and (max-width: 280px){
                margin-bottom: 12px;
                padding: 8px;
                font-size: 13px;
            }
            i{
                font-size: 20px;
            }
        }
        .opcion:hover{
            background-color: white;
            color: $dorado;
        }
    }
}

.contacto-notas{
    margin-left: 0 !important;
    height: 10em;
    border: 1px solid rgba($color: white, $alpha: 0.5) !important;
    padding: 5px;
}

.btn-enviar{
    border-radius: 10px !important;
    background-color: unset;
    color: white;
    @media screen and (max-width: 360px){
        font-size: 20px;
    }

}