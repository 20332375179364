.container{
    // background-color: green;
    a{
        color: black;

    }        
    a:hover{
        color: white;
        text-decoration: none;
    }
    .wrapper{
        // background-color: greenyellow;
        .home{
            // background-color: greenyellow;            
            height: 100vh;
            .content{
                position: absolute;
                bottom: 10vh;
                width: 700px;
                font-style: normal;
                font-weight: normal;
                font-size: 60px;
                line-height: 90px;  
                color: $azul;
                background-color: rgba($color:#fff, $alpha: 0.5);                              
                padding: 5px;
                padding-bottom: 0;
                @media screen and (max-width: 400px){
                    bottom: 30vh;
                }
                .bottom-line{                    
                    font-size: 20px;
                    
                    text-align: center;
                    line-height: 40px; 
                    
                    .line{                        
                        width: 60%;   
                        float: left;
                        border-bottom: 2px solid $azul;  
                        position: absolute;
                        bottom: 0;
                        
                    }
                    .contact{                                        
                        width: 30%;   
                        float: right;                                  
                        border-bottom: 2px solid $azul;  
                        // background-color: rgba($color: #fff, $alpha: 0.5);                        
                        &:hover{
                            color: #BF9000;
                            border-bottom: 2px solid#BF9000;
                            cursor: pointer;
                        }                                           
                    }

                }        
                
            }
            
            
        }
    }
}

.downImg{
    display: flex;
    flex-flow: row;
    justify-content: center;    
    width: 100%;
    .imageDown{
        position: absolute;
        bottom: 10%;   
        margin: 0 auto;    
    }
}

.imageRight{
    float: right;    
    margin-top: 20%
}
.imageLeft{
    margin-top: 130px;

}

/* Use a media query to add a breakpoint at 770px: */
@media screen and (max-width: 767px) {
    .content {        
        // background-color: saddlebrown;
        width: 400px !important;
        font-size: 40px !important;
        line-height: 50px !important;
        .bottom-line{
            .line{
                width: 200px !important;
            }
            .contact{
                width: 170px !important;
            }
            
        }
    }
   
    img{
        width: 250px;
    }
  }
@media screen and (max-width: 610px) {
    .content {      
        width: 300px !important;
        font-size: 30px !important;
        line-height: 40px !important;
       
        .bottom-line{
            .line{
                width: 150px !important;
            }
            .contact{
                width: 130px !important;
            }
            
        }
    }

}

@media screen and (max-width: 280px) {
    .content {      
        width: 200px !important;
        font-size: 30px !important;
        line-height: 40px !important;
       
        .bottom-line{
            .line{
                width: 60% !important;
            }
            .contact{
                width: 30% !important;
                font-size: 10px !important;
                // margin-right: 50px;
            }
            
        }
    }

}
